import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import stars from "../../images/Contact-Us/stars.svg"
import image from "../../images/illustrations/contact_us_Illustration.svg"
import * as styles from "./Banner.module.scss"
import "./Banner.scss"

const ContactForm = React.lazy(() => import("./ContactForm"))
const isSSR = typeof window === "undefined"

const settings = {
  autoplay: true,
  dots: true,
  dotsClass: "contact-dots",
  slidesToShow: 1,
  infinite: true,
  slidesToScroll: 1,
  arrows: false,
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
      },
    },
  ],
}

const Banner = ({ from, strapiData }) => (
  <div className={styles.careers}>
    <Container>
      <Row className=" justify-content-center">
        <Col xl={7} lg={12} className="px-2 mt-5 mt-lg-0 d-flex">
          <div className={styles.illustration}>
            <img
              src={image}
              alt="where we are located"
              placeholder="blurred"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div className={styles.card}>
            {!isSSR && (
              <React.Suspense
                fallback={
                  <div className="text-center">
                    <Spinner
                      variant="light"
                      size="sm"
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                }
              >
                <ContactForm from={from} />
              </React.Suspense>
            )}
          </div>
        </Col>
        <Col xl={5} lg={12} className="pl-0 pr-0">
          <div className={styles.sliderContent}>
            <div>
              <h2>Success Stories</h2>
            </div>
            <Slider {...settings}>
              {strapiData
                ?.sort(() => 0.5 - Math.random())
                ?.map((e, i) => (
                  <div key={i}>
                    <Row>
                      <div className={styles.storiesContent}>
                        <Col md={12} className="mr-3 mr-md-0">
                          {e?.image1 && (
                            <img
                              className={styles.personBg}
                              src={e?.image1[0]?.localFile?.publicURL}
                              alt={e?.image1[0]?.alternativeText}
                              decoding="async"
                              loading="lazy"
                            />
                          )}
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <img
                              src={stars}
                              className={styles.starReview}
                              alt="rating stars"
                              decoding="async"
                              loading="lazy"
                            />
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          ></div>
                          <a
                            href={e?.buttons && e?.buttons[0]?.url}
                            target={"_blank"}
                            className={styles.aurther}
                            rel="noreferrer"
                          >
                            {e?.title}
                          </a>
                          <div className={styles.designation}>
                            {e?.subTitle}
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Banner
