import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dot from "../../images/Contact-Us/dot.svg"
import * as styles from "./TechStack.module.scss"

const TechStack = () => {
  return (
    <div className={styles.TechStack}>
      <Container>
        <div className={styles.techBg}>
          <Row>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>Advanced Tech Stack</p>
            </div>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>Free Consultation</p>
            </div>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>Free Customized Quotes</p>
            </div>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>Free Project Manager</p>
            </div>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>Schedule 1 Week Trial</p>
            </div>
            <div className={styles.tech}>
              <img
                src={dot}
                className={styles.dot}
                decoding="async"
                loading="lazy"
                alt="dots"
              />
              <p>ISO27001-Certified</p>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default TechStack
