import { graphql } from "gatsby"
import React, { useEffect } from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/contact-sections/Banner"
import TechStack from "../components/contact-sections/TechStack"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      image="https://invozone-backend.s3.amazonaws.com/Contact_Us_b7b8e382a7.png"
    />
  )
}
const ContactUsPage = ({ data }) => {
  // console.log(data)

  useEffect(() => {
    localStorage.setItem("popup", false)
  }, [])

  const banner = [
    ...data?.strapiPage?.sections[0]?.cards,
    ...data?.strapiPage?.sections[1]?.cards,
    ...data?.strapiPage?.sections[2]?.cards,
    ...data?.strapiPage?.sections[3]?.cards,
  ]
  return (
    <MainLayout bgChanged={false} removeFooter={true}>
      <Banner strapiData={banner} />
      <TechStack />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "contact-us" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`
export default ContactUsPage
